<template>
    <div class="d-flex">
        <router-link class="reviewer-tag h5 font-weight-bolder text-decoration-none text-dark mb-0"
                     v-bind:to="`/users/user/${ id }`">
            <span class="badge badge-light badge-pill d-flex flex-nowrap align-items-center border pl-1">
                 <b-avatar class="shadow-sm border-0 mr-2"
                           size="sm">
                     <b-spinner v-if="isLoading"></b-spinner>
                    <img class="w-100 h-100"
                         style="object-fit: cover"
                         v-else-if="avatar"
                         v-bind:alt="name"
                         v-bind:src="avatar">
                    <span v-else>
                        <font-awesome-icon v-bind:icon="['fad', 'user']"></font-awesome-icon>
                    </span>
                </b-avatar>
                {{ isLoading ? "Loading..." : name }}
            </span>
        </router-link>
    </div>
</template>

<script>
import { apiUrl, apiVersion, } from "../../../config";
import { BAvatar, BSpinner, } from "bootstrap-vue";
export default {
    name: "ReviewerTag",
    components: {
        BAvatar, BSpinner,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        createdBy: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        currentRegion () {
            return this.$route.params?.region ?? "HKG";
        },
        id () {
            return this.createdBy?._id ?? "";
        },
        name () {
            return `${ this.createdBy?.name ?? "" } (+${ this.createdBy?.countryCode ?? "" } ${ this.createdBy?.phone ?? "" })`;
        },
        avatar () {
            return this.createdBy?.avatar ?
                `${ apiUrl }/${ apiVersion }/user/${ this.id }/avatar` :
                "";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.reviewer-tag {
    .badge {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
        transition: box-shadow 200ms ease-in-out;

        &:hover,
        &:focus {
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        }
    }
}
</style>
